import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export default function Faqs() {

    const data = [
        {
            id: '0',
            question: 'What exactly is USDT_TRC20 and how does it set itself apart from other digital currencies?',
            answer: `USDT_TRC20 is a type of cryptocurrency that runs on the Tron blockchain. It is a stablecoin, meaning its value is pegged to a fiat currency (usually the US dollar), providing stability in the volatile world of cryptocurrencies.`
        },
        {
            id: '2',
            question: 'What are the available choices for customer support in case I encounter any queries or problems?',
            answer: `We offer customer support through email, live chat, and a dedicated support center. Our team is available to assist you with any questions or issues you may encounter while using our zaropay payment gateway.`
        },
        {
            id: '3',
            question: 'How can I deposit USDT_TRC20 into my account?',
            answer: `To deposit USDT_TRC20 into your account, you can send it from your Tron wallet to the deposit address provided by our payment gateway. Deposits are free, and the funds will be credited to your account once confirmed on the Tron network.`
        },
        {
            id: '4',
            question: 'What is the minimum and maximum withdrawal amount for USDT_TRC20?',
            answer: `The minimum and maximum withdrawal amounts depend on the balance of your account.`
        },
        {
            id: '5',
            question: 'What fees are associated with withdrawing USDT_TRC20?',
            answer: `We charge a service fee of 2% for withdrawals, which is deducted from the withdrawal amount. The fee may vary, so please check our fee schedule for the most up-to-date information.`
        },
        {
            id: '6',
            question: 'How can I view my transaction history and account balance?',
            answer: `You can view your transaction history and account balance by logging into your account on our platform. All your recent transactions will be displayed there.`
        },
        {
            id: '7',
            question: 'How long does it take to process a USDT_TRC20 withdrawal?',
            answer: `Withdrawals are typically processed within a short period, but the exact time may vary depending on network conditions. You can see the dashboard updated as withdrawal is done.`
        },
        {
            id: '8',
            question: 'What security measures are in place to protect my USDT_TRC20 funds?',
            answer: `We prioritize the security of your funds. Our platform uses advanced security protocols and encryption to protect your account. Additionally , 2FA is already enabled for every user of our platform.`
        },
        {
            id: '9',
            question: 'Can I send USDT_TRC20 from my account to external wallets or exchanges?',
            answer: `Yes, you can send USDT_TRC20 from your account to external wallets or exchanges. Simply provide the recipient's wallet address and the amount you wish to send.`
        },
        {
            id: '10',
            question: 'What happens if I send the wrong amount or input an incorrect wallet address for a withdrawal?',
            answer: `It's essential to double-check all withdrawal details before confirming the transaction. Once funds are transferred , it is crucial to reverse the process.`
        },
    ];

    const customExpandIcon = ({ isActive }) => (
        <span>
            <div className='w-[30px] h-[30px] rounded-lg bg-black flex justify-center items-center'>
                <img className={`h-4 duration-300 ${isActive ? 'rotate-45' : ''}`} src='https://framerusercontent.com/images/JP915yBZOLgBpZPVNt6EZWidY.svg' alt='icon' />
            </div>
        </span>
    );

    return (
        <div className='my-12 md:py-12 md:px-[140px] px-4' id='faqs'>
            <img className='h-16 mx-auto mb-6' src='https://framerusercontent.com/images/yqBvjq5LkcChWh97DFg9kKbEE1s.svg' alt='img' />
            <p className='text-4xl md:text-5xl text-center font-medium lg:w-[50%] mx-auto'>Your Burning Crypto Questions Answered</p>
            <p className='text-[#4b4b4b] mt-8 text-center lg:w-[45%] mx-auto'>Explore our comprehensive FAQ section to find answers to all your burning questions about cryptocurrency and the CoinUp platform.</p>

            <div className='mt-12 lg:w-[60%] mx-auto'>
                <Collapse bordered={false} expandIcon={customExpandIcon}>
                    {data?.map((faq) => (
                        <Panel header={faq?.question} key={faq?.id} className="custom-panel">
                            <p>{faq?.answer}</p>
                        </Panel>
                    ))}
                </Collapse>
            </div>
        </div>
    )
}
