import './App.scss';
import Home from './Pages/Home/Home.jsx';
import { Toaster } from 'react-hot-toast';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import Terms from './Pages/privacy/Terms.jsx';
import Privacy from './Pages/privacy/Privacy.jsx';
function App() {
  return (
    <Router>
      <div className='app'>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/terms' element={<Terms/>}></Route>
          <Route path='/privacy' element={<Privacy/>}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
