import React from 'react';

export default function AboutApp() {
    return (
        <div className='my-12 md:py-12 md:px-[140px] px-4' id='about'>
            <div className='grid lg:grid-cols-2 items-center'>
                <div className='xl:w-[480px] xl:h-[460px] h-full w-full rounded-2xl mobile-back-background bg-[#fdbc45] flex items-end justify-center px-4 pt-4'>
                    <img src='/main_site/Assets/Images/left-mobile.png' className='xl:w-[360px] xl:h-[380px]' />
                </div>
                <div className='mt-10 ml-0 lg:mt-0 lg:ml-8'>
                    <div className='flex mb-8'>
                        <p className='text-[12px] leading-[12px] py-2 px-3 border border-black rounded-full'>About Zaropay</p>
                    </div>
                    <p className='text-5xl'>What is Zaropay?</p>
                    <p className='text-[#4b4b4b] mt-8'>Introducing our state-of-the-art payment gateway designed specifically for the Tron network – a seamless combination of cutting-edge technology and user-centered design. We prioritize cost-effectiveness by offering zero charges on deposits and an incredibly low withdrawal fee of only 2%, empowering users to efficiently manage their assets. </p>
                    <p className='text-[#4b4b4b] mt-4'>We take pride in our unparalleled support, demonstrating our commitment to providing assistance whenever it is needed. Bid farewell to frustrating delays in customer support – we guarantee immediate responses to ensure that your inquiries are promptly addressed.</p>
                    <p className='text-[#4b4b4b] mt-4'>Transparency lies at the heart of our service, and we keep you informed every step of the way. Enjoy real-time updates on payments received or sent, allowing you to have peace of mind with instant information.</p>
                    <p className='text-[#4b4b4b] mt-4'>What sets us apart is the level of control we give you. With our payment gateway, you have complete autonomy over your wallets, enabling effortless management and monitoring of your funds. Welcome to the future of hassle-free cryptocurrency transactions!</p>
                </div>
            </div>

            <div className='grid lg:grid-cols-3 gap-12 bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8 mt-[90px]'>
                <div>
                    <img className='h-8 w-8' src='https://framerusercontent.com/images/iRlQDTlJ8PZYUzlHskw8mdzA4.svg' alt='icon'/>
                    <p className='mt-6 text-xl font-medium'>Wrong amounts sent? No problem.</p>
                    <p className='mt-4 text-[#4b4b4b]'>Our system is design to handle situation where wrong amounts are sent. It is ensures that there is no loss to user, providing a secure and reliable experience.</p>
                </div>
                <div>
                    <img className='h-8 w-8' src='https://framerusercontent.com/images/csLcnq6YaXZNSONnYMd4QZ408.svg' alt='icon'/>
                    <p className='mt-6 text-xl font-medium'>Seamless Processing of Multiple Payment per Order</p>
                    <p className='mt-4 text-[#4b4b4b]'>Effortlessly, our platform manages multiple payment to the same order ID, preventing confusion and boosting customer satisfaction. Plus, our reliable Crypto Payment Gateway Solution aids in revenue maximization.</p>
                </div>
                <div>
                    <img className='h-8 w-8' src='https://framerusercontent.com/images/DIoAViQB5XwyssP5AOGpBFEoog.svg' alt='icon'/>
                    <p className='mt-6 text-xl font-medium'>Late Payments? No Worries!</p>
                    <p className='mt-4 text-[#4b4b4b]'>Even if the payments are sent after the expiration of the QR code, you can be confident in the knowledge that our system will process them efficiently, guaranteeing successful transactions.</p>
                </div>
            </div>
        </div>
    )
}
