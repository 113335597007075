import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

const Privacy = () => {
    return (
        <>
            <Header />
            <div className='lg:py-6 py-3 bg-gray-100'>
                <h1 className='text-center text-4xl'>Privacy & Policy</h1>
            </div>
            <div className='my-20 md:px-12 px-4  flex justify-center'>
                <div className=' lg:w-2/3'>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Information We Collect:</h2>
                        <p className='px-3 my-8 text-lg'>Personal Information: <br />
                            When you use our services, we only collect email address as personal information to enable 2FA. <br />
                            System Custodial Wallet:<br />
                            Upon user login, a system custodial wallet is automatically generated for you. This wallet is used to store and manage your USDT_TRC20 funds securely.

                        </p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>How We Use Your Information:</h2>
                        <p className='px-3 my-8 text-lg'>We use the collected information for the following purposes:<br />

                            Transaction Processing:<br />
                            To process USDT_TRC20 transactions and facilitate withdrawals.<br />

                            Customer Support:<br />
                            To improve our services, enhance user experience, and develop new features.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Data Security:</h2>
                        <p className='px-3 my-8 text-lg'>We employ industry-standard security measures to protect your data. These measures include encryption, access controls, and regular security audits. While we take all reasonable precautions to safeguard your data, please be aware that no data transmission over the internet is completely secure.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Sharing of Information:</h2>
                        <p className='px-3 my-8 text-lg'>We do not sell, rent, or trade your personal information to third parties. We may share your data with trusted service providers for the following purposes.<br />

                            Fraud Prevention:<br />
                            To prevent fraudulent activities and ensure the security of our platform.<br />

                            Customer Support:<br />
                            To provide effective customer support and address user inquiries and issues.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Service Fees:</h2>
                        <p className='px-3 my-8 text-lg'>We charge service fees for withdrawals. These fees are deducted from the withdrawal amount and are detailed in our fee schedule, which you should review before using our services.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Changes to this Policy:</h2>
                        <p className='px-3 my-8 text-lg'>We may update this Privacy Policy as needed to reflect changes in our practices or legal requirements. Any changes will be posted on our website, and your continued use of our services will signify your acceptance of these changes.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Contact Us:</h2>
                        <p className='px-3 my-8 text-lg'>If you have questions or concerns about this Privacy Policy or our data handling practices, please contact us at  <a href="mailto:pay@zaropay.com" target='_blank' className='underline'>pay@zaropay.com</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy