import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

const Terms = () => {
    return (
        <>
            <Header />
            <div className='lg:py-6 py-3 bg-gray-100'>
                <h1 className='text-center text-4xl'>Terms & Conditions</h1>
            </div>
            <div className='my-20 md:px-12 px-4  flex justify-center'>
                <div className=' lg:w-2/3'>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Acceptance of Terms:</h2>
                        <p className='px-3 my-8 text-lg'>By using the services of zaroPay , you agree to abide by these Terms of Service. If you do not agree with any part of these terms, please do not use our services.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Acceptance of Terms:Use of Services:</h2>
                        <p className='px-3 my-8 text-lg'>Our services are provided for the purpose of processing cryptocurrency transactions, including but not limited to withdrawals and deposits. You agree not to use our services for any illegal or fraudulent activities.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Service Fees:</h2>
                        <p className='px-3 my-8 text-lg'>We charge service fees for processing withdrawals. These fees are detailed in our fee schedule, which you should review before using our services. Deposits are free of charge.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Payment Processing:</h2>
                        <p className='px-3 my-8 text-lg'>We provide payment processing services for cryptocurrency transactions. You acknowledge and agree that the processing of cryptocurrency transactions involves risks, and we are not liable for any losses or damages resulting from cryptocurrency market fluctuations.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Account Security:</h2>
                        <p className='px-3 my-8 text-lg'>You are responsible for the security of your account. You must keep your login credentials confidential and notify us immediately if you suspect unauthorized access to your account.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Termination:</h2>
                        <p className='px-3 my-8 text-lg'>We may, at our discretion, terminate or suspend your account for violations of these terms or other reasons deemed necessary.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Privacy Policy:</h2>
                        <p className='px-3 my-8 text-lg'>Your use of our services is also governed by our Privacy Policy. Please review the Privacy Policy to understand how we collect, use, and protect your personal information.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Intellectual Property:</h2>
                        <p className='px-3 my-8 text-lg'>Our website, platform, and content are protected by copyright and other intellectual property laws. You may not use our intellectual property without our permission.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Changes to Terms:</h2>
                        <p className='px-3 my-8 text-lg'>We may update these Terms of Service as needed. Any changes will be posted on our website, and your continued use of our services will signify your acceptance of these changes.</p>
                    </div>
                    <div className='border-b mb-8 mx-auto'>
                        <h2 className='text-3xl'>Contact Us:</h2>
                        <p className='px-3 my-8 text-lg'>If you have questions or concerns about these Terms of Service, please contact us at <a href="mailto:pay@zaropay.com" target='_blank' className='underline'>pay@zaropay.com</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Terms