import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import axios from 'axios';

export default function HelpAndAssistance() {
    const [captcha, setCaptcha] = useState("")
    const [formData, setFormData] = useState({ name: "", email: "", description: "" })
    const [captchStatus, setCaptchStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const cap = useRef();
    const generateCaptcha = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let code = "";
        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters.charAt(randomIndex);
        }
        setCaptcha(code);
    };
    useEffect(() => {
        generateCaptcha()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (!captchStatus) {
                toast.error('Invalid Captcha')
                setLoading(false)
                return;
            }
            const { data } = await axios.post('https://zaropay.com/api/contactus', formData)
            if (data.status) {
                toast.success(data.message)
                setFormData({ name: "", email: "", description: "" })
                cap.current.value = ""
            }
            setLoading(false)
        } catch (err) {
            toast.error(err.message)
            setLoading(false)
        }
    }
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    return (
        <div className='my-12 md:py-12 md:px-[140px] px-4' id='support'>
            <img className='h-16 mx-auto mb-6' src='https://framerusercontent.com/images/pYXfCr0wyanwbOffAPwKwOJxPE.svg' alt='img' />
            <p className='text-4xl md:text-5xl text-center font-medium'>Get In Touch</p>
            {/* <p className='text-[#4b4b4b] mt-8 text-center lg:w-[45%] mx-auto'>Access our support team for assistance with any questions or issues. We're here to help you navigate the world of cryptocurrency with confidence.</p> */}


            <div className='lg:flex gap-5 mt-12'>
                <div className='lg:w-1/2 lg:mt-0 mv-12'>
                    <div className='bg-[#fafafa] border border-[#f3f3f3] hover:border-[#e7e7e7] duration-300 rounded-3xl p-4 md:p-8 cursor-pointer'>
                        <p className='text-[#4b4b4b] mt-4'>Need assistance or have questions about our payment gateway services? Our dedicated support team is here to help! Feel free to reach out.</p>
                    </div>
                    <div className='bg-[#fafafa] border border-[#f3f3f3] hover:border-[#e7e7e7] duration-300 rounded-3xl p-4 md:p-8 my-6 cursor-pointer'>
                        <div className='h-[48px] w-[48px] border border-[#f3f3f3] rounded-full bg-white flex items-center justify-center'>
                            <img className='h-5 w-5' src='https://framerusercontent.com/images/Au7FjCgWgRlO8Pcdmli2KRDSvI.svg' alt='icon' />
                        </div>
                        <p className='text-xl font-medium mt-4'>General Support</p>
                        <p className='text-[#4b4b4b] mt-4'>pay@zaropay.com</p>
                    </div>
                    <div className='bg-[#fafafa] border border-[#f3f3f3] hover:border-[#e7e7e7] duration-300 rounded-3xl p-4 md:p-8 cursor-pointer my-6'>
                        <div className='h-[48px] w-[48px] border border-[#f3f3f3] rounded-full bg-white flex items-center justify-center'>
                            <img className='h-5 w-5' src='/main_site/Assets/Icons/address.png' alt='icon' />
                        </div>
                        <p className='text-xl font-medium mt-4'>Location</p>
                        <p className='text-[#4b4b4b] mt-4'>4517 Washington Ave. Manchester, Kentucky 39495</p>
                    </div>

                </div>
                <form className='lg:w-1/2 lg:mt-0 mt-8 p-4 bg-gray-50 rounded-3xl ' onSubmit={handleSubmit}>
                    <div className='m-4'>
                        <label htmlFor="name" className='text-lg ms-2'>Name</label>
                        <input required type="text" className='w-full focus:outline-none placeholder:text-gray-300 border-gray-300 border rounded-lg text-xl px-5 py-2 mt-2' placeholder='Enter your name here..' name='name' onChange={handleChange} value={formData.name} />
                    </div>
                    <div className='m-4'>
                        <label htmlFor="email" className='text-lg ms-2'>Email</label>
                        <input required type="email" className='w-full focus:outline-none placeholder:text-gray-300 border-gray-300 border rounded-lg text-xl px-5 py-2 mt-2' placeholder='Enter your email here..' name='email' onChange={handleChange} value={formData.email} />
                    </div>
                    <div className='m-4'>
                        <label htmlFor="Description" className='text-lg ms-2'>Description</label>
                        <textarea required rows={5} type="text" className='w-full focus:outline-none placeholder:text-gray-300 border-gray-300 border rounded-lg text-xl px-5 py-2 mt-2' placeholder='Enter description here..' name='description' onChange={handleChange} value={formData.description}></textarea>
                    </div>
                    <div className="m-4">
                        <div className="flex items-center gap-5">
                            <div className=' w-full flex items-center justify-center'>
                                <div className='bg-black p-0.5 rounded-full cursor-pointer' onClick={e => generateCaptcha()}>
                                    <img src="/main_site/Assets/Icons/re-captcha.png" alt="recaptcha" height={50} />
                                </div>
                                <div className='p-2 rounded-lg  ms-3 px-6 w-full border-gray-300'>
                                    <h2 className='select-none text-3xl font-medium'>{captcha}</h2>
                                </div>
                            </div>
                            <input ref={cap} type="text" className='w-full focus:outline-none placeholder:text-gray-300 border-gray-300 border rounded-lg text-xl px-5 py-2 mt-2' placeholder='Enter Captcha' onChange={e => setCaptchStatus(e.target.value === captcha)} />
                        </div>
                    </div>
                    <div className='m-4'>
                        <button className={`bg-[#fdbc45] text-lg font-semibold w-full py-3 rounded-lg disabled:text-gray-700`} disabled={loading} >{loading ? "Submiting..." : "Submit"}</button>
                    </div>
                </form>
            </div>

        </div>
    )
}
