import React from 'react';

export default function Features() {
    return (
        <div className='my-12 md:px-[140px] px-4' id='features'>
            <p className='md:pt-12 text-5xl font-normal lg:w-[50%]'>The peerless features</p>

            <div className='grid lg:grid-cols-3 gap-6 mt-12'>
                <div className='bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    <p className='text-[#0f0f0f] text-3xl'>Mass Payout</p>
                    <img className='h-[250px] w-[250px] mx-auto my-8' src='https://framerusercontent.com/images/OFLuDR3Cf4IRijJaBL0ST7J8zk.svg' alt='images' />
                    <p className='text-[#4b4b4b]'>Mass payout feature simplifies bulk payments, saving time and effort while streamlining payment processes for enhanced business efficiency.</p>
                </div>
                <div className='bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    <p className='text-[#0f0f0f] text-3xl'>Custody</p>
                    <img className='h-[250px] w-[250px] mx-auto my-8' src='https://framerusercontent.com/images/Zkwuf69ADEBocgNupSKOc4MG90.svg' alt='images' />
                    <p className='text-[#4b4b4b]'>Strengthens security for digital assets, ensuring transactions are highly secure. Advanced measures protect cryptocurrency, letting you focus on business growth confidently</p>
                </div>
                <div className='bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    <p className='text-[#0f0f0f] text-3xl'>Easy Integration</p>
                    <img className='h-[250px] w-[250px] mx-auto my-8' src='https://framerusercontent.com/images/d5XyezVOEBiutIJ44LjYs0UCo.svg' alt='images' />
                    <p className='text-[#4b4b4b]'>Offers easy integration into your CRM, streamlining payment processes and centralizing transaction management. Spend less time on manual tasks and focus more on business growth.</p>
                </div>
                <div className='lg:col-span-2 bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    
                    <div className='md:w-[100%] mt-4'>
                        <p className='text-[#0f0f0f] text-3xl'>Non-KYC Merchant Payment Solution</p>
                        <p className='text-[#4b4b4b] mt-2'>Trusted crypto payment gateway offers a non-KYC merchant payment solution, eliminating time-consuming processes. Start accepting crypto payments effortlessly, attract more customers, and increase sales while ensuring transaction security and privacy.</p>
                    </div>
                    <img className='h-[300px] w-[450px] mx-auto my-4' src='https://framerusercontent.com/images/cmJFL59UdkUqkVAnIFbkifIEM.svg' alt='images' />
                </div>
                <div className='bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    
                    <p className='text-[#0f0f0f] text-3xl mt-4'>Send/Receive</p>
                    <img className='h-[250px] w-[250px] mx-auto my-8' src='https://framerusercontent.com/images/VKmNulBALM4DAoBn6mWgkwTVIL0.svg' alt='images' />
                    <p className='text-[#4b4b4b]'>A trusted crypto payment gateway on the Tron network, enables easy sending and receiving of funds. Whether sending money or receiving payments</p>
                </div>
                <div className='bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    <p className='text-[#0f0f0f] text-3xl'>Multi Crypto Currency Support</p>
                    <img className='h-[250px] w-[250px] mx-auto my-8' src='https://framerusercontent.com/images/DhvP7DwZoJloKqYNKBIA8Dhroz4.svg' alt='images' />
                    <p className='text-[#4b4b4b]'>Effortlessly manage a diverse portfolio of cryptocurrencies in a single, user-friendly interface.</p>
                </div>
                <div className='bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    <p className='text-[#0f0f0f] text-3xl'>Sync Across Devices</p>
                    <img className='h-[250px] w-[250px] mx-auto my-8' src='	https://framerusercontent.com/images/u7orwzIwerNIb9TDWdU8cAY3n8.svg' alt='images' />
                    <p className='text-[#4b4b4b]'>Sync your wallet seamlessly across multiple devices, ensuring access to your assets wherever you go.</p>
                </div>
                <div className='bg-[#fafafa] border border-[#f3f3f3] rounded-2xl p-4 md:p-8'>
                    <p className='text-[#0f0f0f] text-3xl'>24/7 Customer Support</p>
                    <img className='h-[250px] w-[250px] mx-auto my-8' src='https://framerusercontent.com/images/TBUl6dJ1E5A7OCISe7KPdnELY4E.svg' alt='images' />
                    <p className='text-[#4b4b4b]'>Our dedicated team of experts, ready to assist you with any inquiries or issues you may encounter.</p>
                </div>
                <div className='lg:col-span-3 bg-[#ffc5f6] rounded-2xl p-4 md:p-8 xl:flex items-center justify-between flex-wrapa'>
                    <div className='xl:w-[80%]'>
                        <p className='text-[#0f0f0f] text-4xl font-normal lg:w-[60%]'>Unlocking the Future of Payment: Exploring the Power of Crypto Payment Gateway</p>
                        <p className='text-[#4b4b4b] mt-5 lg:w-[70%]'>Experience seamless crypto payment gateway with our user-friendly solution. With our seamless financial transaction solutions, you can securely transfer funds and make payments with ease.</p>
                        <a href='#supported-chains'><button className='bg-[black] text-white font-semibold px-4 py-2 rounded-lg mt-5'>Supported Chains</button></a>
                    </div>
                    <div className='flex'>
                        <div className=' border-2 border-[grey] p-10 rounded-full mx-auto mt-8 xl:mt-0'>
                            <img className='h-[250px] w-[250px]' src='https://framerusercontent.com/images/lOXf7O0V57KOYVY785AjrDqfHE.svg' alt='images' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
