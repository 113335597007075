import React from 'react'

const Pricing = () => {
    return (
        <>
            <div className='md:px-12 px-4 pt-12' id='pricing'>
                <div className='bg-[#272727] p-4 md:p-12 rounded-3xl'>
                    
                    <p className='mx-auto lg:w-[50%] text-white text-center text-4xl md:text-6xl font-normal mt-8'>Fees Structure</p>
                    <div className="my-12 pt-5">
                        <div className="grid grid-cols-10">
                            <div className='col-span-5 my-5'>
                                <div className="flex items-center justify-center h-full">
                                    <h2 className='text-5xl text-white font-bold text-center'>Deposit</h2>
                                </div>
                            </div>
                            <div className='col-span-5 my-5'>
                                <div className="grid grid-cols-2">
                                    <div>
                                        <h2 className='text-white mb-3 text-xl font-bold'>Transaction Fees</h2>
                                        <div className="flex items-center mb-2">
                                            <img src="/main_site/Assets/Icons/Arrow.svg" alt="arrow" className='me-3' />
                                            <h3 className='text-white text-4xl font-semibold'>1.5 USDT</h3>
                                        </div>
                                        <small className='text-white'>
                                            Fixed Normally it would be 13 to 27 trx
                                        </small>
                                    </div>
                                    <div>
                                        <h2 className='text-white mb-3 text-xl font-bold'>Service Fees</h2>
                                        <div className="flex items-center mb-2">
                                            <img src="/main_site/Assets/Icons/Arrow.svg" alt="arrow" className='me-3' />
                                            <h3 className='text-white text-4xl font-semibold'>0 USDT</h3>
                                        </div>
                                        <small className='text-white'>
                                            No Service Fees on Deposit
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-5 mt-12'>
                                <div className="flex items-center justify-center h-full">
                                    <h2 className='text-5xl text-white font-bold text-center'>Withdrawal</h2>
                                </div>
                            </div>
                            <div className='col-span-5 mt-12'>
                                <div className="grid grid-cols-2">
                                    <div>
                                        <h2 className='text-white mb-3 text-xl font-bold'>Transaction Fees</h2>
                                        <div className="flex items-center mb-2">
                                            <img src="/main_site/Assets/Icons/Arrow.svg" alt="arrow" className='me-3' />
                                            <h3 className='text-white text-4xl font-semibold'>1.5 USDT</h3>
                                        </div>
                                        <small className='text-white'>
                                            Fixed Normally it would be 13 to 27 trx
                                        </small>
                                    </div>
                                    <div>
                                        <h2 className='text-white mb-3 text-xl font-bold'>Service Fees</h2>
                                        <div className="flex items-center mb-2">
                                            <img src="/main_site/Assets/Icons/Arrow.svg" alt="arrow" className='me-3' />
                                            <h3 className='text-white text-4xl font-semibold'>1.5 USDT</h3>
                                        </div>
                                        <small className='text-white'>
                                            2% of the transaction amount.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing


{/* <div id='priceMenu' className='bg-[#272727]'>
                        <div>
                            <p>Deposit</p>
                            <div>
                                <p>Trasaction Fees</p>
                                <p>1.5 USDT</p>
                                <p>Fixed Normally it would be 13 to 27 trx</p>
                            </div>
                            <div>
                                <p>Service Fees</p>
                                <p>0 USDT</p>
                                <p>No Service Fees on Deposit</p>
                            </div>
                        </div>
                        <div>
                            <p>Withdrawal</p>
                            <div>
                                <p>Trasaction Fees</p>
                                <p>1.5 USDT</p>
                                <p>Fixed Normally it would be 13 to 27 trx</p>
                            </div>
                            <div>
                                <p>Service Fees</p>
                                <p>2 %</p>
                                <p>2 % of the trasation amount.</p>
                            </div>
                        </div>
                        <div>Note:0.5 USDT markup earnig is set for merchants on each deposit. It can be adjusted according to the merchant's requirement</div>
                    </div> */}